import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';
// Material components
import { Grid, Button, ButtonGroup, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PopupMenu } from 'components/popups';
import filterSrc from 'assets/images/calendar/icon_filter.svg';
import { toggleFilterSidebar } from 'redux/global';

const styles = (theme) => ({
  button: {
    borderRadius: 4,
  },
  calendarHeaderFilter: {
    position: 'releative',
  },
  calendarViewFilter: {
    position: 'absolute',
    zIndex: 10,
  },
  calendarCurrentNav: {
    textAlign: 'right',
    width: '100%',
  },
  selected: {
    backgroundColor: '#043D8B !important',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  teamDialogTitleText: {
    fontWeight: 600,
    fontSize: 20,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  monthContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
    maxHeight: 44,
  },
  todayContainer: {
    margin: '0 5px 0 0',
  },
  textStyle: {
    fontWeight: 600,
    fontSize: 14,
  },
  backgroundBtn: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  btnGroup: {
    marginRight: 15,
  },
  shadowNone: {
    boxShadow: 'none',
  },
  btnAdd: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

const CalendarListToolbar = (props) => {
  const { classes, onNavigate, label, view, onView, views,
    openJobForm, localizer: { messages }, isFiltering
  } = props;

  const dispatch = useDispatch();

  const selected = (value) => value === view;
  const { t } = useTranslation('Calendar');
  const menuData = [
    {
      menuItems: [
        {
          label: t('Job:newJob'),
          action: () => openJobForm(),
        },
      ],
    },
  ];

  return (
    <div className="rbc-btn-group">
      <div
        className={classes.calendarHeaderFilter}
        style={{ marginBottom: 16 }}
      >
        <div className={classes.calendarViewFilter}>
          <Grid container direction="row">
            <Grid item className={classes.todayContainer}>
              <Button
                className={classNames(
                  'toolbar-navigation-button',
                  classes.textStyle,
                  classes.backgroundBtn,
                  classes.shadowNone
                )}
                size="medium"
                variant="contained"
                onClick={() => onNavigate('TODAY')}
              >
                {t('Today').toUpperCase()}
              </Button>
            </Grid>
            <Grid item className={classes.monthContainer}>
              <div className={classes.calendarCurrentNav}>
                <Button
                  className="toolbar-navigation-button"
                  type="button"
                  onClick={() => onNavigate('PREV')}
                >
                  <ChevronLeft className="navigate-icon" />
                </Button>

                <span className={classNames('today-label', classes.textStyle)}>
                  {label.toUpperCase()}
                </span>

                <Button
                  className="toolbar-navigation-button"
                  type="button"
                  onClick={() => onNavigate('NEXT')}
                >
                  <ChevronRight className="navigate-icon" />
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.calendarCurrentNav}>
          <Grid container direction="row" justify="flex-end">
            <Grid item className={classes.btnGroup}>
              <ButtonGroup style={{ height: '100%' }}>
                {views.map((item) => (
                  <Button
                    key={item}
                    size="medium"
                    style={{ border: 'none' }}
                    className={classNames(
                      classes.backgroundBtn,
                      classes.button,
                      classes.textStyle,
                      classes.shadowNone,
                      {
                        [classes.selected]: selected(item),
                      }
                    )}
                    onClick={() => onView(item)}
                    variant="contained"
                  >
                    {t(messages[item]).toUpperCase()}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>

            <Button
              size="medium"
              variant="contained"
              style={{ border: 'none', marginRight: 15 }}
              onClick={() => dispatch(toggleFilterSidebar(true))}
              className={classNames(
                'toolbar-navigation-button',
                classes.textStyle,
                classes.backgroundBtn,
                classes.shadowNone
              )}
            >
              <img
                className={classes.imgFilter}
                src={filterSrc}
                alt="icon_filter"
              />
              {t('filter')}: { isFiltering ? t('Common:on') : t('Common:off') }
            </Button>

            <Grid item>
              <PopupMenu
                menuData={menuData}
                btnTitle={t('Common:create')}
                btnIcon
                textStyle={{ fontWeight: 600, fontSize: 14, paddingLeft: 0 }}
                isIconFirst
                iconAdd
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};


export default withStyles(styles)(CalendarListToolbar);
