import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import CardCustom from "../Card";
import { useDispatch } from "react-redux";
import { getTotalJobsSummary } from "redux/dashboard";
import { moment } from "lib/app-moment";
import { Grid } from "@material-ui/core";
import styles from "../../dashboard.module.scss";
import { Skeleton } from "@material-ui/lab";
import { showErrorMessage } from "lib/notifier";

const JOB_SUMMARY_LINE_CHART = "JOB_SUMMARY_LINE_CHART";

const TotalJobSummary = ({
  t,
  selectedDateRange,
  isParentLoading,
  latestUpdate,
  selectedCategories,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const generateDate = (dates) => {
    if (!Array.isArray(dates) || !dates.length) return [];
    return dates.map((date) => moment(date).format("MMM DD"));
  };

  const drawChart = (values) => {
    const dates = generateDate(values.datesArray);
    setDateRange(dates);
    setData({
      labels: dates,
      datasets: [
        {
          label: t("created"),
          data: values.createdJobs,
          borderColor: ["#6412EA"],
          backgroundColor: ["#6412EA"],
        },
        {
          label: t("completed"),
          data: values.completedJobs,
          borderColor: ["#2697FF"],
          backgroundColor: ["#2697FF"],
        },
        {
          label: t("cancelled"),
          data: values.cancelledJobs,
          borderColor: ["#EE2727"],
          backgroundColor: ["#EE2727"],
        },
      ],
    });
  };

  useEffect(() => {
    if (
      !Array.isArray(selectedCategories) ||
      !selectedCategories.length ||
      isParentLoading
    )
      return;

    const { endDate, startDate } = selectedDateRange;
    const categoryIds = selectedCategories.map((category) => category.id);

    (async () => {
      const params = {
        type: JOB_SUMMARY_LINE_CHART,
        categoryIds,
        ...(endDate ? { to: moment(endDate).toISOString() } : {}),
        ...(startDate ? { from: moment(startDate).toISOString() } : {}),
      };
      setIsLoading(true);
      const response = await dispatch(getTotalJobsSummary(params));
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      drawChart(response);
    })();
  }, [
    selectedDateRange,
    isParentLoading,
    latestUpdate,
    selectedCategories,
    dispatch,
    drawChart,
  ]);

  if (isParentLoading || isLoading)
    return <Skeleton style={{ transform: "unset" }} height={240} />;

  return (
    <CardCustom title={t("totalJobSummary")} customCardStyle={styles.lineChart}>
      <Grid style={{ zIndex: 1, position: "relative" }}>
        <Line
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: "index",
              intersect: false,
            },
            plugins: {
              legend: {
                display: true,
                position: "right",
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                  pointStyle: "line",
                  font: {
                    weight: 700,
                    lineHeight: 10,
                    color: "#A2A2A2",
                  },
                },
              },
              tooltip: true,
            },
            scales: {
              x: {
                ticks: {
                  callback: (value, index, values) => {
                    if (index === 0 || index + 1 === values.length)
                      return dateRange[index];
                    return null;
                  },
                },
              },
            },
          }}
        />
      </Grid>
    </CardCustom>
  );
};

export default TotalJobSummary;
