import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector, Field, FieldArray } from 'redux-form';
import { getProducts, addProduct, clearProductsServices } from 'redux/product';
import { formatPrice } from 'lib/formatter';

// Material components
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
  Box,
  withStyles,
  Card
} from '@material-ui/core';

// Customised components

import { ServiceProductInput, InventoryInput } from '../inputs';
import { TextInput } from 'components/inputs';

import addServiceIcon from 'assets/images/icons/blue_add_circle.svg';
import gridIcon from 'assets/images/icons/grid.svg';
import descriptionIcon from 'assets/images/icons/fi_align_left.svg';
import deleteIcon from 'assets/images/icons/u_trash.svg';
import unitPriceIcon from 'assets/images/icons/unit_price.svg';

// Component styles
import { ProductInputsFormStyle } from './styles';

const jobSelector = formValueSelector('jobForm');

const PRODUCT_SAMPLE = {
  name: '',
  description: '',
  quantity: 1,
  unitPrice: '',
  total: '',
  inventories: [
    {
      name: '',
      model: '',
      serialNumber: '',
      quantity: 1,
      total: '0.00',
      unitPrice: '0.00',
    },
  ],
  tax: 0,
  totalIncludeTax: 0,
};

const mapStateToProps = (state) => ({
  isCustomProduct: state.job.isCustomProduct,
  jobProductFormValue: jobSelector(state, 'products'),
  currency: state.auth.currentUser.companies[0].currency,
  productDetailsFormValue:
    state.form.productDetails && state.form.productDetails.values,
  products: state.product.products || [],
  services: state.product.services || [],
});

const mapDispatchToProps = (dispatch) => ({
  initializeProductForm: (jobProductFormValue) => {
    Object.keys(jobProductFormValue).forEach((key) => {
      dispatch(change('productDetails', `${key}`, jobProductFormValue[key]));
    });
  },
  getProducts: () => {
    dispatch(getProducts());
  },
  addProduct: (item, callback) => {
    dispatch(addProduct(item, callback));
  },
  clearProductsServices: () => {
    dispatch(clearProductsServices());
  },
  updateFormProductsValue: (newValue) => {
    dispatch(change('jobForm', 'products', newValue));
  },
});

const renderTypography = ({ input, currency }) => {
  return (
    <Typography variant="h5">{formatPrice(input.value, currency)}</Typography>
  );
};

const ProductInputsForm = (props) => {
  const {
    fields,
    products,
    services,
    translate,
    currency,
    classes,
  } = props;

  const servicesArray = Object.keys(services).map((service) => {
    const item = services[service];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const productsArray = Object.keys(products).map((product) => {
    const item = products[product];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const groupedOptions = [
    {
      title: translate('Common:services'),
      suggestions: servicesArray,
    },
    {
      title: translate('Common:products'),
      suggestions: productsArray,
    },
  ];

  const inventoriesOptions = [
    {
      title: translate('Common:products'),
      suggestions: productsArray,
    },
  ];

  const handleClickAddService = () => fields.push(PRODUCT_SAMPLE);

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Typography variant="h4">{translate('service')}</Typography>
        <Button onClick={handleClickAddService} className={classes.btn_text}>
          <img className="mr_5" src={addServiceIcon} alt="addServiceIcon" />
          {translate('addAnotherService')}
        </Button>
      </Grid>

      {fields.map((field, index) => (
        <>
          <Box key={field} mt={2}>
            <Card className="pt_10" style={{ overflow: 'unset' }}>
              {!!index && (
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  className="mt_5 pr_10"
                >
                  <Button onClick={() => fields.remove(index)}>
                    <img src={deleteIcon} alt="deleteIcon" className="mr_5" />
                    <Typography>{translate('Common:delete')}</Typography>
                  </Button>
                </Grid>
              )}
              <Grid container className={classes.card_padding} wrap="nowrap">
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.service_label}
                >
                  <img className="mr_5" src={gridIcon} alt="grid_icon" />
                  <Typography variant="h5">{translate('service')}</Typography>
                </Box>
                <Field
                  name={`${field}.name`}
                  placeholder={translate('Common:name')}
                  component={ServiceProductInput}
                  variant="outlined"
                  margin="dense"
                  index={index}
                  translate={translate}
                  options={groupedOptions}
                />
              </Grid>

              <Grid container className={classes.card_padding} wrap="nowrap">
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.service_label}
                >
                  <img
                    className="mr_5"
                    src={descriptionIcon}
                    alt="descriptionIcon"
                  />
                  <Typography variant="h5">
                    {translate('Common:description')}
                  </Typography>
                </Box>
                <Field
                  name={`${field}.description`}
                  placeholder={translate('Common:description')}
                  component={TextInput}
                  variant="outlined"
                  margin="dense"
                />
              </Grid>

              <Grid container className={classes.card_padding}>
                <Grid item container spacing={2} sm={8} md={10} wrap='nowrap'>
                  {/* <Grid item container sm={6} md={6} alignItems="center" wrap='nowrap'>
                    <Box display="flex" className={classes.service_label}>
                      <img src={unitPriceIcon} alt="unitPriceIcon" />
                      <Typography variant="h5">
                        {translate('unitPrice')}
                      </Typography>
                    </Box>
                    <Grid item>
                      <Field
                        name={`${field}.unitPrice`}
                        placeholder="Unit Price ( tax excluded)"
                        component={TextInput}
                        variant="outlined"
                        margin="dense"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currency}
                            </InputAdornment>
                          ),
                          inputProps: { min: 0, step: '.01' },
                        }}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid item container alignItems="center">
                    <Grid item className="mr_5">
                      <Typography variant="h5">
                        {translate('quantity')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Field
                        name={`${field}.quantity`}
                        placeholder="Qty"
                        component={TextInput}
                        variant="outlined"
                        margin="dense"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={4}
                  md={2}
                  container
                  alignItems="center"
                  justify="flex-end"
                >
                  <Typography className={classes.total} variant="h5">
                    {translate('total')}
                  </Typography>
                  <Field
                    name={`${field}.total`}
                    placeholder={translate('Job:totalPrice')}
                    component={renderTypography}
                    variant="outlined"
                    margin="dense"
                    currency={currency}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.items_wrapper}>
                <FieldArray
                  name={`${field}.inventories`}
                  component={InventoryInput}
                  translate={translate}
                  currency={currency}
                  parentField={field}
                  options={inventoriesOptions}
                  cardIndex={index}
                />
              </Grid>
            </Card>
          </Box>
        </>
      ))}
    </>
  );
};

export default compose(
  withStyles(ProductInputsFormStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductInputsForm);
