import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserDetails, updateCategories } from "redux/dashboard";
import { Grid, Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import LazyLoad from "react-lazyload";

import { showErrorMessage } from "lib/notifier";

import styles from "./dashboard.module.scss";
import Admin from "./Admin";
import Client from "./Client";
import { TimeRange, Categories, EmptyState } from "./component";

import chartSrc from "assets/images/dashboard/header-chart.svg";
import logoSrc from "assets/images/dashboard/logo-mark.svg";
import AvatarDefault from "assets/images/dashboard/default-avatar.svg";

const CLIENT_TAG = "ENTERPRISE_PARTNER";
const SERVISHERO_TAG = "SERVISHERO";

const Dashboard = (props) => {
  const { t } = useTranslation("Dashboard");
  const dispatch = useDispatch();
  const companyDetails = useSelector(
    (state) =>
      state.auth.currentUser.companies && state.auth.currentUser.companies[0]
  );
  const persistCategories = useSelector(
    (state) => state.dashboard.persistCategories
  );
  const date = new Date();

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(
      23,
      59,
      59,
      999
    ),
    key: "selection",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [staffCategories, setStaffCategories] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [enterpriseAccountId, setEnterpriseAccountId] = useState("");

  const handleSaveCategories = (categories) => {
    const deepCopyCategories = [...categories];
    let filteredCategories = [];
    if (persistCategories) {
      // get categories from redux first then compare with assigned categories (get from api /me)
      filteredCategories = persistCategories.filter((category) =>
        deepCopyCategories.find((item) => item.id === category.id)
      );
    }
    // if still remain categories show it on dashboard
    if (filteredCategories.length) {
      setSelectedCategories(filteredCategories);
      setStaffCategories(filteredCategories);
      return dispatch(updateCategories(filteredCategories));
    }
    // else get list from api me
    // setSelectedCategories(deepCopyCategories);
    // setStaffCategories(deepCopyCategories);
    // dispatch(updateCategories(deepCopyCategories));
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await dispatch(getUserDetails());
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      setUserDetails(response);
      const defaultCategory = response.categories || [];
      handleSaveCategories(defaultCategory);
    })();
  }, [dispatch, handleSaveCategories]);

  useEffect(() => {
    if (companyDetails.tag !== CLIENT_TAG.toLowerCase()) return;
    setEnterpriseAccountId(companyDetails.id);
  }, [companyDetails]);

  let Comp = Client;
  if (CLIENT_TAG === SERVISHERO_TAG) Comp = Admin;
  if (!selectedCategories.length) Comp = EmptyState;

  return (
    <>
      {isLoading ? (
        <Skeleton style={{ transform: "unset" }} height={250} />
      ) : (
        <Box
          py={3}
          px={4}
          className={styles.header}
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            style={{ zIndex: 1 }}
          >
            <Box display="flex" alignItems="flex-start">
              <LazyLoad height={72} once>
                <img
                  className={styles.avatar}
                  src={userDetails.avatar || AvatarDefault}
                  alt="avatar"
                />
              </LazyLoad>
              <Box ml={2}>
                <Typography variant="h5" className={styles.textGrey}>
                  {t("hello")}
                </Typography>
                <Typography variant="h4">{userDetails.fullName}</Typography>
              </Box>
            </Box>
            <Box display="flex" mt={3}>
              <Categories
                t={t}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                staffCategories={staffCategories}
                enterpriseAccountId={enterpriseAccountId}
              />
              <TimeRange
                t={t}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            </Box>
          </Box>
          <LazyLoad once style={{ zIndex: 1 }}>
            <Grid style={{ zIndex: 1, height: "100%" }}>
              <img src={chartSrc} alt="chartSrc" style={{ height: "100%" }} />
            </Grid>
          </LazyLoad>
          <LazyLoad once className={styles.floatingImg}>
            <img src={logoSrc} alt="logoSrc" />
          </LazyLoad>
        </Box>
      )}

      {/* content */}
      <Comp
        {...props}
        t={t}
        selectedDateRange={selectedDateRange}
        selectedCategories={selectedCategories}
        isParentLoading={isLoading}
      />
    </>
  );
};

export default Dashboard;
