import React, { useState, useEffect } from "react";
import {
  BigCalendar,
  ModalJobDetails,
  TimeRangeSelector,
} from "pages/Dashboard/components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEnterpriseVisits } from "redux/enterprise";
import moment from "moment";
import { Box, Grid, Typography } from "@material-ui/core";
import { showErrorMessage } from "lib/notifier";
import { ToolsBar, Event, CategorySelector } from "./components";
import { getUserDetails, updateCategories } from "redux/dashboard";
import styles from "./styles.module.scss";
import { BackdropCustom, useBackdrop } from "lib/loading";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { QUERY_STRING_DECODE, QUERY_STRING_ENCODE } from "common/constant";

const date = new Date();
let interval;

const EnterpriseCalendar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("Calendar");
  const [open, withLoading] = useBackdrop();
  const [eventsList, setEventList] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(
      23,
      59,
      59,
      999
    ),
    key: "selection",
  });

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState({});
  const [isOpenJobDetailsModal, setOpenJobDetailsModal] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const prepareResponse = (data) => {
    const visitList = [];
    data.forEach((visit) => {
      visit.start = moment(visit.start).toDate();
      visit.end = moment(visit.end).toDate();
      visitList.push(visit);
    });
    return visitList;
  };

  const persistCategories = useSelector(
    (state) => state.dashboard.persistCategories
  );

  const handleSaveCategories = (categories) => {
    const deepCopyCategories = [...categories];
    let filteredCategories = [];
    if (persistCategories) {
      // get categories from redux first then compare with assigned categories (get from api /me)
      filteredCategories = persistCategories.filter((category) =>
        deepCopyCategories.find((item) => item.id === category.id)
      );
    }

    // if still remain categories show it on dashboard
    if (filteredCategories.length) {
      setSelectedCategory(filteredCategories);
      return dispatch(updateCategories(filteredCategories));
    }

    // else get list from api me
    setSelectedCategory(deepCopyCategories);
    dispatch(updateCategories(deepCopyCategories));
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(getUserDetails());
      if (response.e) return showErrorMessage(response.e);
      const defaultCategory = response.categories || [];
      handleSaveCategories(defaultCategory);
    })();
  }, [dispatch, handleSaveCategories]);
  const updateQueryParamsUrl = (values) => {
    const stringified = queryString.stringify(values, QUERY_STRING_ENCODE);
    history.replace({ ...location, search: stringified });
  };

  useEffect(() => {
    if (!Array.isArray(selectedCategory) || !selectedCategory.length) return;

    const getVisits = async () => {
      const searchParams = queryString.parse(
        location.search,
        QUERY_STRING_DECODE
      );
      const paramsVisits = {
        jobCategoryIds: selectedCategory.map((item) => item.id),
        from: moment(selectedDateRange.startDate).toISOString(),
        to: moment(selectedDateRange.endDate).toISOString(),
      };
      const params = {
        ...searchParams,
        ...paramsVisits,
      };
      updateQueryParamsUrl(params);
      let result = await withLoading(dispatch(getEnterpriseVisits({ params })));
      if (result.e) return showErrorMessage(result.e);

      result = prepareResponse(result);
      setEventList(result);
    };

    getVisits();

    interval = setInterval(async () => {
      getVisits();
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  }, [selectedDateRange, selectedCategory]);

  const CustomToolbar = (props) => {
    return (
      <Grid className={styles.positionAbsolute}>
        <ToolsBar {...props} />
      </Grid>
    );
  };

  const onRangeChange = (rangeDate) => {
    setSelectedDateRange({
      ...selectedDateRange,
      endDate: rangeDate.end,
      startDate: rangeDate.start,
    });
  };

  const onSelectEvent = (event) => {
    setSelectedVisit(event);
    setOpenJobDetailsModal(true);
  };

  const handleClose = () => setOpenJobDetailsModal(false);

  return (
    <>
      <BackdropCustom open={open} />
      <Box px={2} style={{ position: "relative" }}>
        {/* have to move filter outside toolbar, bc menu will be close after set date
        it refresh anchorEL */}
        <Box
          className={styles.filter_wrapper}
          px={2}
          display="flex"
          width="50%"
          justifyContent="flex-end"
        >
          <Box display="flex" alignItems="center">
            <Typography
              className={`${styles.font_weight_700} ${styles.text_margin} ${styles.text_responsive}`}
            >
              {t("timeRange").toUpperCase()}
            </Typography>
            <TimeRangeSelector
              t={t}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              showDateOnly
              customTextContainer={styles.datePickerWrapper}
            />
          </Box>
          <CategorySelector
            t={t}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Box>
        {/* calendar  */}
        <BigCalendar
          eventsList={eventsList}
          CustomToolbar={CustomToolbar}
          Event={Event}
          onRangeChange={onRangeChange}
          onSelectEvent={onSelectEvent}
        />
      </Box>
      {isOpenJobDetailsModal && (
        <ModalJobDetails
          openJobDetails={isOpenJobDetailsModal}
          handleClose={handleClose}
          jobId={selectedVisit && selectedVisit.job && selectedVisit.job.id}
        />
      )}
    </>
  );
};

export default EnterpriseCalendar;
