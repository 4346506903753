import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouterWithSubRoutes from './RouterWithSubRoutes';

import { TrackingMap, WorkerTrackingDetails } from 'pages/Dashboard/views/Gps';
import { Calendar } from 'pages/Dashboard/views/Calendar';
import {
  ClientList,
  NewClient,
  UpdateClient,
  ClientDetails,
} from 'pages/Dashboard/views/Clients';
import {
  InvoiceList,
  InvoiceDetails,
  NewInvoice,
  UpdateInvoice,
} from 'pages/Dashboard/views/Invoices';
import { StaffList, NewStaff, UpdateStaff } from 'pages/Dashboard/views/Staffs';
import {
  JobDetails,
  JobList,
  NewJob,
  UpdateJob,
  NewServiceReport,
  UpdateServiceReportDetails,
  ServiceReportDetails,
} from 'pages/Dashboard/views/Jobs';
import {
  WorkSettings,
  BusinessSettings,
} from 'pages/Dashboard/views/BusinessManagement';
import { TimesheetList } from 'pages/Dashboard/views/Timesheet';
import {
  QuoteList,
  NewQuote,
  UpdateQuote,
  QuoteDetails,
} from 'pages/Dashboard/views/Quotes';
import { AccountSettingForm } from 'pages/Dashboard/views/AccountSetting';
import { DashboardLayout, TrackingLayout } from 'pages/Dashboard/layouts';
import { ProductList } from 'pages/Dashboard/views/Products';
import Settings from 'pages/Dashboard/views/Settings';
import UnderDevelopment from 'pages/Dashboard/views/UnderDevelopment';
import NotFound from 'pages/Dashboard/views/NotFound';
import GreetingPage from 'pages/GreetingPage';
import StaffInvitation from 'pages/StaffInvitation';
import ClientTracking from 'pages/ClientTracking';
import Dashboard from 'pages/Dashboard/views/Dashboard';
import {
  ManageSubscription,
  PaymentPending,
} from '../pages/Dashboard/views/Subscription';
import AccountDeactivated from 'pages/Dashboard/AccountDeactivated';
import CategoryView from 'pages/Dashboard/views/Category';
import {
  EnterpriseList,
  NewEnterprise,
  EditEnterprise,
  EnterpriseAccountDetails,
} from 'pages/Dashboard/views/EnterpriseAccount';
import {
  EnterpriseCalendar,
  EnterpriseJobList,
  EnterpriseJobDetails,
} from 'pages/Dashboard/views/Enterprise';
import {
  HubList,
  HubDetails,
  NewHub,
  EditHub,
} from 'pages/Dashboard/views/HubManagement';

import { VIEWS_NAME } from 'common/constant';

export const dashboardRouteList = [
  {
    path: '/dashboard',
    component: DashboardLayout,
    translationNameSpace: 'Calendar',
    routes: [
      {
        path: '',
        component: Dashboard,
      },
    ],
  },
  {
    title: 'calendar',
    path: '/calendar',
    view: VIEWS_NAME.calendar,
    component: DashboardLayout,
    translationNameSpace: 'Calendar',
    routes: [
      {
        path: '',
        component: Calendar,
      },
    ],
  },
  {
    title: 'GPS',
    path: '/gps',
    view: VIEWS_NAME.gps,
    component: TrackingLayout,
    routes: [
      {
        path: '',
        component: TrackingMap,
      },
      {
        subtitle: 'workerTrackingDetails',
        path: '/:userId/details',
        component: WorkerTrackingDetails,
      },
    ],
  },
  {
    title: 'clients',
    path: '/clients',
    view: VIEWS_NAME.clients,
    component: DashboardLayout,
    translationNameSpace: 'Client',
    routes: [
      {
        subtitle: 'clientList',
        path: '',
        component: ClientList,
      },
      {
        subtitle: 'clientDetails',
        path: '/:clientId',
        component: ClientDetails,
      },
      {
        subtitle: 'New Client',
        path: '/new',
        component: NewClient,
      },
      {
        subtitle: 'editClient',
        path: '/:clientId/edit',
        component: UpdateClient,
      },
    ],
  },

  {
    title: 'staff',
    path: '/teams',
    view: VIEWS_NAME.staff,
    component: DashboardLayout,
    translationNameSpace: 'Staff',
    routes: [
      {
        path: '',
        component: StaffList,
      },
      {
        subtitle: 'addNewStaff',
        path: '/new',
        component: NewStaff,
      },
      {
        subtitle: 'editStaff',
        path: '/:staffId/edit',
        component: UpdateStaff,
      },
    ],
  },

  {
    path: '/work',
    view: VIEWS_NAME.work,
    component: DashboardLayout,
  },

  {
    path: '/requests',
    view: VIEWS_NAME.requests,
    component: JobList,
    routes: [
      {
        path: '/requests/:requstId',
        component: NewJob,
      },
    ],
  },

  {
    title: 'quote',
    path: '/quotes',
    view: VIEWS_NAME.quote,
    component: DashboardLayout,
    translationNameSpace: 'Quote',
    routes: [
      {
        path: '',
        component: QuoteList,
      },
      {
        subtitle: 'newQuote',
        path: '/new',
        component: NewQuote,
      },
      {
        subtitle: 'quoteDetails',
        path: '/:quoteId',
        component: QuoteDetails,
      },
      {
        subtitle: 'editQuote',
        path: '/:quoteId/edit',
        component: UpdateQuote,
      },
    ],
  },

  {
    title: 'jobs',
    path: '/jobs',
    view: VIEWS_NAME.jobs,
    component: DashboardLayout,
    translationNameSpace: 'Job',
    routes: [
      {
        path: '',
        component: JobList,
      },
      {
        subtitle: 'jobDetails',
        path: '/:jobId',
        component: JobDetails,
      },
      {
        subtitle: 'newJob',
        path: '/new',
        component: NewJob,
      },
      {
        subtitle: 'newJob',
        path: '/new/:quoteId',
        component: NewJob,
      },
      {
        subtitle: 'editJob',
        path: '/:jobId/edit',
        component: UpdateJob,
      },
      {
        subtitle: 'newReport',
        path: '/:jobId/service-report/new',
        component: NewServiceReport,
      },
      {
        subtitle: 'reportDetails',
        path: '/:jobId/service-report/:reportId/view',
        component: ServiceReportDetails,
      },
      {
        subtitle: 'editReportDetails',
        path: '/:jobId/service-report/:reportId/edit',
        component: UpdateServiceReportDetails,
      },
    ],
  },

  {
    title: 'invoices',
    path: '/invoices',
    view: VIEWS_NAME.invoices,
    component: DashboardLayout,
    translationNameSpace: 'Invoice',
    routes: [
      {
        path: '',
        component: InvoiceList,
      },
      {
        subtitle: 'newInvoice',
        path: '/new',
        component: NewInvoice,
      },
      {
        subtitle: 'invoiceDetails',
        path: '/:invoiceId',
        component: InvoiceDetails,
      },
      {
        subtitle: 'editInvoice',
        path: '/:invoiceId/edit',
        component: UpdateInvoice,
      },
    ],
  },

  {
    title: 'businessManagement',
    path: '/business_management',
    component: DashboardLayout,
    routes: [
      {
        subtitle: 'businessSettings',
        path: '/business-settings',
        component: BusinessSettings,
      },
      {
        subtitle: 'workSettings',
        path: '/work-settings',
        component: WorkSettings,
      },
    ],
  },

  {
    title: 'productsServices',
    path: '/products',
    component: DashboardLayout,
    translationNameSpace: 'Product',
    routes: [
      {
        path: '',
        component: ProductList,
      },
    ],
  },

  {
    path: '/settings',
    component: Settings,
  },

  {
    path: '/under-development',
    component: UnderDevelopment,
  },

  {
    path: '/greeting',
    component: GreetingPage,
  },

  {
    path: '/staff-invitation',
    component: StaffInvitation,
  },

  {
    path: '/not-found',
    component: NotFound,
  },

  {
    title: 'timesheet',
    path: '/timesheet',
    component: DashboardLayout,
    translationNameSpace: 'Timesheet',
    routes: [
      {
        path: '',
        component: TimesheetList,
      },
    ],
  },

  {
    title: 'accountSettingTitle',
    path: '/account-setting',
    component: DashboardLayout,
    routes: [
      {
        path: '',
        component: AccountSettingForm,
      },
    ],
  },

  {
    title: 'planBilling',
    path: '/subscription',
    component: DashboardLayout,
    translationNameSpace: 'Account',
    routes: [
      {
        path: '',
        component: ManageSubscription,
      },
      {
        path: '/pending',
        component: PaymentPending,
      },
    ],
  },

  {
    title: 'Enterprise Account',
    path: '/enterprise',
    component: DashboardLayout,
    translationNameSpace: 'Account',
    routes: [
      {
        path: '',
        component: EnterpriseList,
      },
      {
        path: '/new',
        component: NewEnterprise,
      },
      {
        path: '/:enterpriseId/edit',
        component: EditEnterprise,
      },
      {
        path: '/:enterpriseId',
        component: EnterpriseAccountDetails,
      },
    ],
  },

  {
    title: 'tracking',
    path: '/tracking',
    component: ClientTracking,
    translationNameSpace: 'Tracking',
  },
  {
    path: '/company/deactivated',
    component: AccountDeactivated,
  },
  {
    title: 'category',
    path: '/category',
    component: DashboardLayout,
    translationNameSpace: 'Sidebar',
    routes: [
      {
        path: '',
        component: CategoryView,
      },
    ],
  },
  {
    title: 'Enterprise Calendar',
    path: '/enterprise-calendar',
    component: DashboardLayout,
    view: VIEWS_NAME.enterpriseCalendar,
    translationNameSpace: 'Calendar',
    routes: [
      {
        path: '',
        component: EnterpriseCalendar,
      },
    ],
  },
  {
    title: 'Jobs',
    path: '/enterprise-jobs',
    component: DashboardLayout,
    view: VIEWS_NAME.enterpriseJobs,
    translationNameSpace: 'Enterprise',
    routes: [
      {
        path: '',
        component: EnterpriseJobList,
      },
      {
        path: '/:jobPublicId',
        component: EnterpriseJobDetails,
      },
    ],
  },
  {
    title: 'Hub',
    path: '/hub',
    component: DashboardLayout,
    translationNameSpace: 'Hub',
    routes: [
      {
        path: '',
        component: HubList,
      },
      {
        path: '/new',
        component: NewHub,
      },
      {
        path: '/:hubId/edit',
        component: EditHub,
      },
      {
        path: '/:hubId',
        component: HubDetails,
      },
    ],
  },
];

export default (_props) => {
  return (
    <Switch>
      {dashboardRouteList.map((route, key) => {
        return <RouterWithSubRoutes key={key} {...route} />;
      })}
      <Redirect to="/not-found" />
    </Switch>
  );
};
